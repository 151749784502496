.TimeLineDate {
    color:  #005f2e;
    background-image: linear-gradient(41deg, #ffffff 0, transparent 100%) !important;
    padding-right: 25px;
    padding-left: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-shadow: 0px 0px 5px 10px rgba(255,2555,255,0.5);
    display: block;
}
.vertical-timeline {
    background-color: rgba(33 150 243,0.5)
}
.vertical-timeline::before {
    background-color: #8bc43f;
    background-image: linear-gradient(0deg, #8bc43f 0, #005f2e 100%) !important;
}

.TimeLineTextContainerBackgroundEven {
    background-image: linear-gradient(221deg, #8bc43f 0, #005f2e 100%) !important;
}
.TimeLineTextContainerBackgroundOdd {
    background-image: linear-gradient(41deg, #8bc43f 0, #005f2e 100%) !important;
}

.TreeDetails {
    padding-top: 20px;
    padding-left: 10px;
    font-size: 18px;
}

.sliderBorder {
    border: 1px solid black;
}