@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');
.card-bg{
    background: #f7fafc   !important;
}
.card-bg form{
    margin: 20px 20px;
}
.table{
    padding: 0px;
}
.dataTables_filter{
    text-align: end !important;
}
.table {
    width: 100%;
    overflow-y: auto;
}
.dataTables_wrapper {
    padding: 10px;
}
.table th{
    font-size: 12px !important;
}
.dataTables_info{
    margin-top: 20px !important;
}
.dataTables_wrapper .dataTables_paginate{
    margin-top: 20px !important;
    text-align: right !important;
   
}
.page-item.disabled .page-link {
    color: #8898aa;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
    width: 65px;
    display: inline-table;
    text-align: -webkit-center;
    text-align: -moz-center;
}
.page-item .page-link, .page-item span{
    border-radius: 0 !important;
}
.card-view{
    padding: 0px 0px 0px 0;
    margin: 0;
    position: relative;
}
.card-view h3{
    margin: 2px 0 0 0;
    color:#000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100ch;
}
.card-view p{
    font-size: 14px;
    padding: 2px 0px 6px 0;
    color: #000;
    margin:0;
    text-align: justify;
    line-height: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100ch;
}
.card-view span{
    font-size: 11px !important;
    color: #000;
    margin: 0;
}
.gray{
    color:#8a8888 !important;
}
.white{
    color:#fff !important;
}
.bottom-a{
    position: absolute;
    bottom: 18px;
    right: 20px;
}
.card-view .badge{
    font-size: 11px;
}
hr{
    margin: 10px 10px 10px 30px !important;
}
.pag{
    margin-top: 10px;
}

.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 50%!important;
}
.pagination>li {
    display: inline;
}

.pagination>.disabled>a, .pagination>.disabled>a:focus, .pagination>.disabled>a:hover, .pagination>.disabled>span, .pagination>.disabled>span:focus, .pagination>.disabled>span:hover {
    color: #777;
    background-color: #fff;
    border-color: #ddd;
    cursor: not-allowed;
}
.pagination>li>a, .pagination>li>span {
    position: relative;
    float: left;
    padding: 6px 12px;
    line-height: 1.42857143;
    text-decoration: none;
    color: #337ab7;
    background-color: #fff;
    border: 1px solid #ddd;
    margin-left: 4px;
    border-radius: 50%!important;
    width: fit-content;
    height: 36px;
    text-align: center;
}

.pagination>li:first-child>a, .pagination>li:first-child>span {
    margin-left: 0;
    border-radius: 50%!important;
    font-size: .875rem;
    display: flex;
    width: 36px;
    height: 36px;
    margin: 0 0px;
    padding: 0;
    border-radius: 50%!important;
    align-items: center;
    justify-content: center;
}

.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
    z-index: 3;
    color: #fff;
    border-color: #5e72e4;
    background-color: #5e72e4;
    cursor: default;
}
.headline{
    color: #000;
    font-weight: 600;
    margin-top: 0px;
    font-size: 20px;
}
.venue{
    color: #000;
    font-weight: 600;
    margin: 5px 0;
}
.venue .ni{
    color: #03acca !important;
}
.date{
    color: #000;
    font-weight: 500;
    margin: 5px 0;
}
.t-no .ni{
    color: #03acca !important;
}
.t-no{
    color: #000;
    font-weight: 500;
    margin: 5px 0;
}
.date .ni{
    color: #03acca !important;
}
.status{
    color: #000;
    font-weight: 500;
    margin: 5px 0;
}
.status .ni{
    color: #03acca !important;
}
.status .fas{
    color: #03acca !important;
}
.bx-img{
    height:165px !important;
    width: 100%;
    object-fit: cover;
}
.slider{
    height: 328px !important;
}
.description{
    text-align: justify;
    margin:0;
    padding: 14px 15px 0px 15px;
    color: #000; 
}
.slide{
    background-size: contain !important;
    margin-top: 0px;
}
.c-date{
    font-size: 11px;
    color: #8a8888;
    padding-bottom: 5px;
}
.description label{
    margin-bottom: 0px !important;
}
.log{
    width: 100%;
    padding: 0;
    font-size: 14px;
    background: #fff;
    border: 1px solid #cfecff;
    padding: 5px 10px;
    margin-bottom: 10px;
    border-radius: 7px;
}
/* .log:last-child {
    margin-bottom: 10px;
  } */
  .log-head{
    width: 100%;
    padding: 0;
    font-size: 14px;
    border-bottom: 1px solid #e6e6e6;
    padding: 15px 15px;
    font-weight: 700;
}
.log-head:last-child {
    border-bottom: none;
  }
  .log-box{
      margin: 20px 0;
      width: 100%;
  }
  
.avatar img {
    width: 100%;
    border-radius: 50%;
    height: 100%;
}
  .avatar2{
    height: 30px;
    width: 30px;
  }
  .avatar2 img{
      border-radius:50px !important; 
  }
  .card-title{
      font-weight: 400;
      font-size: 12px;
  }
  .cat-img{
      width: 100%;
      height: 180px !important;
      object-fit: cover;
  }
  .small{
      font-size: 12px !important;
      padding: 10px 0px 0 0px;
  }
  .des{
    font-size: 14px !important;
    padding: 0px 0px 5px 0px;
    color: #000;
}
.card-footer{
    padding: 15px 15px !important;
}
.chart{
    height: 350px !important;
}
.progress2{
    width: 100%;
    height: auto;
    background: #f8f9fe;
    text-align: center;
}
.progress-dash{
    width: 100%;
    height: auto;
    background: #f8f9fe;
    padding-top: 300px;
    text-align: center;
}
 .log:hover{
    background: #efecec;
    cursor: pointer;
}
.card-profile-image img{
    width: 200px;
    height: 180px;
}
.capcha-img img{
    margin-top: -30px;
}
@media (max-width: 767.98px){
.navbar-collapse.collapsing, .navbar-collapse.show{
    background: #fff !important;
}
.rounded-circle{
    border-radius:50px !important;
}
.bx-img{
    height: 100%;
    width: 100%;
}
.slide{
    background-size: cover !important;
    margin-top: 0px;
}
.card-view{
    padding: 0 0 8px 0;
}
.bottom-a{
    position: relative;
    bottom: 0px;
    left: 0px;
    top:5px;
}
.slider{
    height: 200px !important;
}
.headline{
    margin-top: 15px;
    font-size: 20px;
    line-height: 22px;
}
.description{
    padding: 0px 15px 0px 15px;
}
.t-no{
    margin: 15px 0 5px 0;
}
.c-date{
    padding-bottom: 0px;
}
.chart{
    height: auto !important;
}
.progress-dash{
    padding-top: 150px;
}
.card-profile-image .rounded-circle{
    border-radius: 100% !important;
}
.card-profile-image img{
    width: 150px;;
    height: 150px;
}
.log{
    
}
.log:hover{
    background: #ccc;
    cursor: pointer;
}
.capcha-img img {
    margin-top: -15px;
    margin-bottom: -25px;
}
}
.error-message{
    color: red;
}
.sep{
    height:10px;
}
.bg-gradient-default1{
    background: -webkit-linear-gradient(41deg, #8bc43f 0, #005f2e 100%) !important;
	background: linear-gradient(41deg, #8bc43f 0, #005f2e 100%) !important;
}
.bg-gradient-info{
    background: -webkit-linear-gradient(41deg, #8bc43f 0, #005f2e 100%) !important;
	background: linear-gradient(41deg, #8bc43f 0, #005f2e 100%) !important;
}
.input-group-alternative {
    background: #fff;
}
.react-datepicker-wrapper {
    width: 87%;
    background: #fff;
    /* float: left; */
}

.card-bg-view{
    background-color: #fff  !important;
    padding: 20px 20px 20px 20px;
    font-family: 'Roboto', sans-serif;
}
.card-bg-view .card-header{
    padding: 0px 0px 20px 0;
    margin: 0 0 20px 0;
    font-weight: 500;
}
.box{
    width: 100%;
    background: #fff;
    z-index: 9999999 !important;
    padding:0px;
    border-radius:5px; 
}
.pad-t{
    margin-top: 33px;
}
.head{
    font-weight: 300;
    cursor: pointer;
    font-size: 30px;
    color: #000;
    padding-left: 10px;
}

.footer{
  /* position: fixed; */
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 30px 50px 30px 50px;
  z-index: -1;
}
.input-group-alternative {
    background: #fff;
    border: 1px solid #cad1d7;
}
.alert-success {
    background-color: #fff; 
   border-color: #0ac282;
   color: #0ac282;
   font-size: 15px;
   margin-bottom: 2rem;
}
.alert-info {
    background-color: #fff;
    border-color: #2dcee3;
    color: #2dcee3;
    font-size: 15px;
    margin-bottom: 2rem;
}
.alert-warning {
    background-color: #fff;
    border-color: #fe9365;
    color: #fe9365;
    font-size: 15px;
    margin-bottom: 2rem;
}
.alert-danger {
    background-color: #fff;
    border-color: #fe5d70;
    color: #fe5d70;
    font-size: 15px;
    margin-bottom: 2rem;
}
.alert-primary {
    background-color: #fff;
    border-color: #01a9ac;
    color: #01a9ac;
    font-size: 15px;
    margin-bottom: 2rem;
} 
.alert-dismissible .close > span:not(.sr-only) {
    font-size: 1.5rem;
    background-color: transparent;
    color: rgba(14, 14, 14, 0.6) !important;
}
.accordion__button:before {
    content:none !important;
}
.accordion__button:after{
    content:none !important;
}
.accordion__button {
    background-color: #fff !important;
    padding: 5px 15px 5px 15px !important;
}
.accordion {
    border: 1px solid rgb(245, 245, 245,.2) !important;
    border-radius: 2px;
}
.accordion__panel{
    background: #ffffff;
    border-radius:5px;
    padding: 20px 30px 30px 30px !important;
    border-top: 1px solid #eae9e9;
    -webkit-transition: width 2s; /* Safari prior 6.1 */
    transition: width 2s; 
}
.form-group{
    margin-bottom: 0;
}

/*for login page */

.bg-secondary-login{
   background: #fff;
   /* border-radius: 25px; */
    border: #fff dashed 1px !important;
}
.login-bg .bg-gradient-info{
    background: #f8f9fe !important;
}
.login-bg {
    background:url(../img/bg.jpg) no-repeat;
    background-size: cover;
    height: 100vh;
}
@media only screen and (max-width: 600px) {
    .login-bg {
        height: 100vh;
    }
  }
.text-light{
    color: #000 !important;
}
.custom {
    font-size: 5em;
  }
  .custom-empty {
    font-size: 21px !important;
    color: blue !important;
  }
  .custom-full {
    color: green !important;
    font-size: 21px !important;
  }
  .custom-full {
    color: green !important;
    font-size: 21px !important;
  }
  .custom-full-l {
    color: green !important;
    font-size: 13px !important;
  }
.rating-size{
    font-size: 21px !important;
}

#ex2 .fa-stack[data-count]:after{
    position:absolute;
    right:0%;
    top:1%;
    content: attr(data-count);
    font-size:60%;
    padding:.6em;
    border-radius:999px;
    line-height:.75em;
    color: white;
    background:rgba(255,0,0,.85);
    text-align:center;
    min-width:2em;
    font-weight:bold;
  }
  #ex2 a{
      color: #fff !important;
  }
  .small-date{
      font-size: 12px;
  }
  .r-font span{
      font-size: 21px !important;
  }
  .green{
      color: #0ac282;
  }
  .pad{
      margin-bottom: 350px;
  }
  .padDashboard{
    margin-bottom: 700px;
}
.react-tabs__tab--selected{
    font-weight: 600;
    background: #82be43 !important;
    color: #fff;
}
.react-tabs__tab{
    font-weight: 600;
    font-size: 15px;
}
.react-tabs__tab-list {
    border-bottom: 1px solid #e4e1e1;
}
@media (max-width: 350px){
    .navbar-vertical .navbar-brand-img, .navbar-vertical .navbar-brand>img{
        margin-left: -30px;
        }
        .navbar-vertical .navbar-brand {
            margin-right: -55px;
        }
}
.privacy{
    width: 100%;
    background: #fff;
    height: 600px;
    overflow-y: scroll;
}
.privacy h2{
    font-size: 30px;
}
.greyIcon{
    color:#8c95aa;
}
.capchaImage{
    background: none;
    height: 47px;
}
.onlineUser{
    background: #8bc43f;
}
.pujaListHeading{
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: #11cdef!important;
    height: 50px;
}
.contestBody{
    padding-left: 0px!important;
    padding-right: 0px !important;
}
.contest-card-view{
    padding: 0px 0px 0px 0;
    margin: 0;
    position: relative;
    height: 75px;
}
.contest-card-view h3{
    margin: 2px 0 0 0;
    color:#000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100ch;
}
.contest-card-view p{
    font-size: 14px;
    padding: 2px 0px 6px 0;
    color: #000;
    margin:0;
    text-align: justify;
    line-height: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100ch;
}
.contest-card-view span{
    font-size: 11px !important;
    color: #000;
    margin: 0;
}
.gray{
    color:#8a8888 !important;
}
.white{
    color:#fff !important;
}
.bottom-a{
    position: absolute;
    bottom: 18px;
    right: 20px;
}
.contest-card-view .badge{
    font-size: 11px;
}
.contest-bx-img{
    height:75px !important;
    width: 100%;
    object-fit: cover;
}
.leaderboard-body{
    padding: 5px 5px 0px 5px !important;
}

.six-point-star {
    position: absolute;
    width: 0; 
    height: 0; 
    border-left: 50px solid transparent; 
    border-right: 50px solid transparent; 
    border-bottom: 80px solid blue;
 }
 .six-point-star:after {
    content:"";
    position: absolute;
    width: 0; 
    height: 0; 
    border-left: 50px solid transparent; 
    border-right: 50px solid transparent; 
    border-top: 80px solid blue;
    margin: 30px 0 0 -50px;
 }
 .box1{
    position: relative;
    display: inline-block; /* Make the width of box same as image */
}
 .box1 .textcenter{
    position: absolute;
    z-index: 999;
    margin: 0 auto;
    left: 0;
    right: 0;        
    text-align: center;
    top: 9%; /* Adjust this value to move the positioned div up and down */
   /*  background: rgba(0, 0, 0, 0.8); */
    font-family: Arial,sans-serif;
    color: #fff;
    width: 60%; /* Set the width of the positioned div */
}
.box1 .textbottom{
    position: absolute;
    z-index: 999;
    margin: 0 auto;
    left: 0;
    right: 0;        
    text-align: center;
    top: 61%; /* Adjust this value to move the positioned div up and down */
    /*background: rgba(0, 0, 0, 0.8); */
    font-family: Arial,sans-serif;
    color: #fff;
    width: 100%; /* Set the width of the positioned div */
}

.districtOptions {
    height: 100px;
}